import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Patch Day" />
    <br style={{ "margin-top": "0.5em" }}></br>
    <h1 style={{ "text-align": "center"}}>Under construction</h1>
    <p style={{ "text-align": "center" }}>Coming soon!</p>
    <div style={{ marginBottom: `1.45rem`, width: '50%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
      <img src={`./under_construction.png`} alt={""} />
    </div>
  </Layout>
)

export default IndexPage
